import './App.css';
import { Route, Routes } from "react-router-dom";
import Review from './pages/Review';
import Navbar from './components/Navbar';

function App() {
  return (
    <div className="">
      <Navbar />
      <Routes>
        <Route path="/" element={<Review />} />
      </Routes>
    </div>
  );
}

export default App;
