import React, { useState, useEffect } from "react";
import axios from "axios";
import Footer from "./Footer";
import "../css/Dropdown.css"; // Make sure to import the CSS file

function Review() {
  const [reviews, setReviews] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    comment: "",
  });

  const getToken = () => {
    return localStorage.getItem("token");
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    console.log("Fetching reviews...");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_PRO_URL}/api/review/getallreview`
      );
      console.log("Response received:", response);
      if (
        response.data.status === "success" &&
        Array.isArray(response.data.contacts)
      ) {
        setReviews(response.data.contacts);
        console.log("Reviews set:", response.data.contacts);
      } else {
        console.log("Error: Invalid response format or no contacts found");
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = getToken();
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_PRO_URL}/api/review/send-review`,
        formData,
        { withCredentials: true, headers: { Authorization: `Bearer ${token}` } }
      );
      fetchReviews();
      setShowSuccessMessage(true);
      setFormData({ name: "", email: "", comment: "" });
    } catch (error) {
      console.error("Error submitting review:", error);
    }
  };

  const currentDate = new Date().toLocaleDateString();

  return (
    <div className="" style={{ fontFamily: "Poppins" }}>
      <form onSubmit={handleSubmit}>
        <div className="w-[85%] mx-auto my-10 text-left">
          <h1 className="xl:text-[18px] text-[14px] md:text-[17px] font-semibold py-3 bg-[#F0AF73] text-left mb-4">
            Share Your Experience: Your Feedback Matters to Us! Leave a Review
            and Help Us Improve Our Products and Services for You and Others in
            Our Community.
          </h1>
          <h1 className="text-justify xl:text-[15px] text-[13px] xl:leading-7 leading-5">
            Welcome to our Review Page! Your feedback matters to us, and we
            greatly appreciate you taking the time to share your thoughts.
            Here, you can leave a review of your experience with our products
            or services. Your reviews help us understand what we're doing well
            and where we can improve, ensuring that we continue to provide the
            best possible experience for our customers. Thank you for your
            support and for being a valued part of our community. We look
            forward to hearing from you!
          </h1>
          <div className="flex flex-col mt-6 text-[14px] xl:text-[20px]">
            <div className="flex gap-2">
              Comment
              <p className="text-red-700 font-bold">*</p>
            </div>
            <textarea
              className="border-2 border-gray-400 lg:hidden rounded-md p-2 mt-1 xl:w-[1020px] xl:h-[350px] lg:w-[980px] lg:h-[350px] relative"
              rows="3"
              placeholder="1500 Characters Max"
              name="comment"
              value={formData.comment}
              onChange={handleChange}
            ></textarea>
            <textarea
              className="border-2 border-gray-400 md:hidden sm:hidden hidden lg:block rounded-md p-2 mt-1 xl:w-[1020px] xl:h-[350px] lg:w-[870px] lg:h-[350px]"
              rows="3"
              placeholder=""
              name="comment"
              value={formData.comment}
              onChange={handleChange}
              style={{  zIndex: 1}}
            ></textarea>

            <span className="absolute h-fit w-fit xl:mt-[340px] xl:ml-[800px] lg:mt-[340px] lg:ml-[700px] md:hidden sm:hidden hidden lg:block xl:block text-gray-400 font-medium">
              1500 Characters Max
            </span>
          </div>

          <div className="flex mt-4">
            <p className="text-red-700 mr-2 font-bold">*</p>
            <div className="flex flex-col gap-3">
              <label className="font-bold">Your Email</label>
              <input
                type="text"
                name="email"
                id=""
                value={formData.email}
                onChange={handleChange}
                className="xl:w-[591px] xl:h-[30px] lg:w-[591px] lg:h-[30px] sm:w-[400px] sm:h-[30px] outline-2 border-2 border-black rounded-sm mb-4"
              />
            </div>
          </div>
          <div className="flex">
            <p className="text-red-700 mr-2 font-bold">*</p>
            <div className="flex flex-col gap-3">
              <label className="font-bold">Your Name</label>
              <input
                type="text"
                name="name"
                id=""
                value={formData.name}
                onChange={handleChange}
                className="xl:w-[591px] xl:h-[30px] lg:w-[591px] lg:h-[30px] sm:w-[400px] sm:h-[30px] outline-2 border-2 border-black rounded-sm"
              />
            </div>
          </div>
          <button className="bg-[#F58634] rounded-lg hover:bg-orange-600 w-[220px] text-xl h-[40px] mt-3 text-white font-semibold mb-7">
            Post Comment
          </button>
          <p className="ml-5 mt-4">
            Note: Your name and email will be displayed here
          </p>
          <h1 className="bg-[#F58634] p-2 rounded-lg mt-6 text-black font-normal">
            Recent Reviews
          </h1>
          {reviews.map((review, index) => (
            <div key={index} className="border-b-2 pb-5 pt-5">
              <p className="font-semibold">{review.name}</p>
              <p className="font-semibold mb-4">Posted on {currentDate}</p>
              <p className="text-justify leading-7">{review.comment}</p>
            </div>
          ))}
        </div>
      </form>
      <Footer />
      {showSuccessMessage && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-8">
            <p className="text-lg font-bold mb-4 text-black">
              Your Review has been successfully submitted!
            </p>
            <button
              onClick={() => setShowSuccessMessage(false)}
              className="bg-[#F58634] hover:bg-[#b16834] text-white font-bold py-2 px-4 rounded inline-flex items-center"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Review;
