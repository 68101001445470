// src/components/Navbar.js
import React, { useState, useRef, useEffect } from 'react';
import Dropdown from './Dropdown';
import '../css/Dropdown.css';
import sidebars from "../assets/sidebars.jpeg";

const Navbar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    setShowSidebar(prevState => !prevState);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setShowSidebar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='navbar h-[150px] flex justify-between items-center px-5'>
      <img 
        src={require("../assets/logo_updated.png")} 
        alt="Logo" 
        className='logo h-20 w-24 xl:ml-10 sm:h-20 sm:w-24 md:h-28 md:w-36 ml-5 sm:ml-7 mt-4' 
      />
      {/* Show sidebar icon on smaller screens */}
      <div className='sm:hidden flex items-center '>
        <div className="sidebar-icon bg-black mt-4 w-fit h-fit" onClick={toggleSidebar}>
          <img src={sidebars} alt="sidebar" className='h-10 w-10' />
        </div>
      </div>
      <div className='hidden sm:flex mx-auto mt-14 rounded-lg  xl:gap-10 text-lg'>
        <Dropdown
          title="Resume Writing"
          items={[
            { title: "Text Resume", link: "https://doledgeindia.com/Text-Resume-Entry-Level" },
            { title: "Visual Resume", link: "https://doledgeindia.com/Visual-Resume-Entry-Level" },
            { title: "Resume Critique", link: "https://doledgeindia.com/Resume-Critique" },
            { title: "Resume Quality Score", link: "https://doledgeindia.com/Resume-Quality-Score" },
            { title: "Cover Letter", link: "https://doledgeindia.com/Cover-Letter" },
          ]}
        />
        <Dropdown
          title="International Resume"
          items={[
            { title: "International Resume", link: "https://doledgeindia.com/International-Resume-Entry-Level" },
            { title: "Visual International Resume", link: "https://doledgeindia.com/Visual-Resume-International-Entry-Level" },
            { title: "Zap Your Resume-International", link: "https://doledgeindia.com/Zap-Your-Resume-International" },
          ]}
        />
        <Dropdown
          title="Career Growth"
          items={[
            { title: "Zap Your Resume", link: "https://doledgeindia.com/Zap-Your-Resume" },
            { title: "Highlight Your Resume", link: "https://doledgeindia.com/Highlight-Your-Resume" },
            { title: "Job Search Assistant", link: "https://doledgeindia.com/Job-Search-Assistant" },
            { title: "Interview Preparation", link: "https://doledgeindia.com/Interview-Preparation" },
            { title: "Job Alert On Mail and SMS", link: "https://doledgeindia.com/Job-Alert-On-Mail-And-SMS" },
            { title: "Social Profiler", link: "https://doledgeindia.com/Social-Profiler" },
            { title: "Personal Portfolio", link: "https://doledgeindia.com/Personal-Portfolio" },
          ]}
        />
        <Dropdown
          title="Screening Services"
          items={[
            { title: "Employee Background Verification", link: "https://doledgeindia.com/Employee-Background-Verification" },
            { title: "Document Verification", link: "https://doledgeindia.com/Document-Verification" },
          ]}
        />
        <Dropdown
          title="Combos"
          items={[
            { title: "Combo Resume Pack", link: "https://doledgeindia.com/Combo-Resume-Pack" },
            { title: "Combo Highlights", link: "https://doledgeindia.com/Combos-Highlights" },
            { title: "Combo Job Boost", link: "https://doledgeindia.com/Combo-Job-Boost" },
            { title: "Combo International Packs", link: "https://doledgeindia.com/Combo-International-Packs" },
          ]}
        />
      </div>
      {/* Sidebar for smaller screens */}
      <div ref={sidebarRef} className={`sm:hidden text-lg fixed top-11 right-0 h-[2000px] bg-slate-50 p-4 transition-transform transform ${showSidebar ? 'translate-x-0' : 'translate-x-full'}`}>
        <div className='flex flex-col space-y-10'>
          <Dropdown
            title="Resume Writing"
            items={[
              { title: "Text Resume", link: "https://doledgeindia.com/Text-Resume-Entry-Level" },
              { title: "Visual Resume", link: "https://doledgeindia.com/Visual-Resume-Entry-Level" },
              { title: "Resume Critique", link: "https://doledgeindia.com/Resume-Critique" },
              { title: "Resume Quality Score", link: "https://doledgeindia.com/Resume-Quality-Score" },
              { title: "Cover Letter", link: "https://doledgeindia.com/Cover-Letter" },
            ]}
          />
          <Dropdown
            title="International Resume"
            items={[
              { title: "International Resume", link: "https://doledgeindia.com/International-Resume-Entry-Level" },
              { title: "Visual International Resume", link: "https://doledgeindia.com/Visual-Resume-International-Entry-Level" },
              { title: "Zap Your Resume-International", link: "https://doledgeindia.com/Zap-Your-Resume-International" },
            ]}
          />
          <Dropdown
            title="Career Growth"
            items={[
              { title: "Zap Your Resume", link: "https://doledgeindia.com/Zap-Your-Resume" },
              { title: "Highlight Your Resume", link: "https://doledgeindia.com/Highlight-Your-Resume" },
              { title: "Job Search Assistant", link: "https://doledgeindia.com/Job-Search-Assistant" },
              { title: "Interview Preparation", link: "https://doledgeindia.com/Interview-Preparation" },
              { title: "Job Alert On Mail and SMS", link: "https://doledgeindia.com/Job-Alert-On-Mail-And-SMS" },
              { title: "Social Profiler", link: "https://doledgeindia.com/Social-Profiler" },
              { title: "Personal Portfolio", link: "https://doledgeindia.com/Personal-Portfolio" },
            ]}
          />
          <Dropdown
            title="Screening Services"
            items={[
              { title: "Employee Background Verification", link: "https://doledgeindia.com/Employee-Background-Verification" },
              { title: "Document Verification", link: "https://doledgeindia.com/Document-Verification" },
            ]}
          />
          <Dropdown
            title="Combos"
            items={[
              { title: "Combo Resume Pack", link: "https://doledgeindia.com/Combo-Resume-Pack" },
              { title: "Combo Highlights", link: "https://doledgeindia.com/Combos-Highlights" },
              { title: "Combo Job Boost", link: "https://doledgeindia.com/Combo-Job-Boost" },
              { title: "Combo International Packs", link: "https://doledgeindia.com/Combo-International-Packs" },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
